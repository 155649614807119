import React from 'react'
import { graphql } from 'gatsby'
import SEO from '../components/SEO'
import { RichText } from 'prismic-reactjs'
import Layout from '../components/layouts'
import htmlSerializer from '../utils/htmlSerializer'

const Page = ({data}) => {
  if (!data) return null
  const document = data.allPrismicAudioItem.edges[0].node

    return (
      <Layout>
        <SEO title={RichText.asText(document.data.title)} />
        <div className="mt-5">

          <div className="page__content">
            <div className="hero__media mx-auto mb-3">
            </div>
          </div>

          <div className="page__content">
            <div class="copy__body__center">
              <div>
                <div className="eyebrow">Design</div>
                <h1 className="display__title mb-5">{RichText.asText(document.data.title)}</h1>
                <div className="description mb-5">
                  {RichText.asText(document.data.description)}
                </div>
              </div>
            </div>
          </div>

        </div>
      </Layout>
    )
  }

// Query for the Blog Post content in Prismic
export const query = graphql`
query AudioQuery($uid: String) {
  allPrismicAudioItem(filter: {uid: {eq: $uid}}) {
    edges {
      node {
        url
        uid
        type
        id
        data {
          title {
            type
            text
          }
          thumbnail {
            url
          }
          skills {
            type
            text
          }
          description {
            type
            text
          }
        }
      }
    }
  }
}
`

export default Page
